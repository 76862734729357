import React, { Component } from 'react';
import "./DashBoardUserManagemet.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import moment from "moment";
import Drawer from '@mui/material/Drawer';
import CloseRounded from '@mui/icons-material/CloseRounded';
import DataTable from 'react-data-table-component';
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Paper, Select, Snackbar, TextField } from '@mui/material';
import { Send, Add, PictureAsPdf, InfoRounded } from '@mui/icons-material';
import UserInfo from './UserInfo';
import SendNotification from './SendNotification';
import PendingNotification from './PendingNotification';
import ExportDataUser from './ExportDataUser';
import DateRangePicker from '../../lib/DateRangePicker';
import PieChart from '../GoogleAnalytics/PieChart';
import LineChart from '../GoogleAnalytics/LineChart';
import BarChart from '../GoogleAnalytics/BarChart';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

const defaultLabel = [];
class DashboardUserManagement extends Component {
    state = { 
        columns : [
            {
                name: "USSD",
                selector: row => row.ussd,
                sortable: true
            },
            {
                name: "Nama",
                selector: row => row.fullname,
                sortable: true
            },
            {
                name: "Role",
                selector: row => row.role,
                sortable: true
            },
            {
                name: "Subscription",
                selector: row => row.stores !== undefined && row.stores.length > 0 ? row.stores[0].subscribe_type : "Free",
                sortable: true
            },
            // {
            //     name: "Referral",
            //     selector: row => row.referral,
            //     sortable: true
            // },
            {
                name: "Join Date",
                selector: row => row.created_at,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.created_at).unix();
                    const b = moment(rowB.created_at).unix();

                    return a >= b ? 1 : -1
                    // return parseInt(a) > parseInt(b) ? 1 : -1

                }
            },
            {
                name: "Last Activity",
                selector: row => row.last_activity_date,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.last_activity_date).unix();
                    const b = moment(rowB.last_activity_date).unix();

                    return a >= b ? 1 : -1
                    // return parseInt(a) > parseInt(b) ? 1 : -1

                }
            },
            {
                name: "Action",
                selector: row => row.action
            }
        ],
        data : [], 
        top: false,
        left: false,
        bottom: false,
        right: false,
        selectedUser : {},
        //all, owner store, cashier
        dialogMode: this.DIALOG_MODE_USER_INFO,
        role: "all",
        isLoading: false,
        keyword: "",
        field: "referal_code",
        listPendingNotifications: [],
        // startDate: moment(new Date()).format("YYYY-MM-DD"),
        // endDate: moment(new Date()).format("YYYY-MM-DD"),
        // startDate: null,
        // endDate: null,
        startDate: moment(new Date()).subtract(1, "Y").format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        snackbarOpen: false,
        snackbarMessage: "",
        chartKategoriUser: {labels: defaultLabel, datasets: []},
        chartUser: {labels: defaultLabel, datasets: []},
        activeUsersData: {labels: defaultLabel, datasets: []},
        jumlahUser: 0
    }

    DIALOG_MODE_USER_INFO = "userInfo";
    DIALOG_MODE_KIRIM_NOTIFIKASI = "kirimNotifikasi";
    DIALOG_MODE_PENDING_NOTIFIKASI = "pendingNotifikasi";

    options = {
        filterType: 'checkbox',
    };

    loadUsers = (role) => {

        if(role == null | role === undefined | role === "all"){
            role = "";
        }
                
        const params = {
            filter: {
                $or: [
                    {
                        fullname: {
                            $regex: this.state.keyword,
                            $options: "i"
                        }
                    },
                    {
                        ussd: this.state.keyword
                    },
                    {
                        referal_code: this.state.keyword
                    }
                ],
                
            },
            sort: {
                created_at: -1
            },
            limit: 2000
        }

        if(this.state.startDate !== null && this.state.endDate !== null){
            params.filter.created_at = {$gte: this.state.startDate, $lte: this.state.endDate};
        }

        if(role !== null && role !== undefined && role !== "all" && role !== ""){
            params.filter.role = role;
        }
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.getUserList(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("USERSRESPONSE", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            responseData.data.forEach(element => {
                // console.log(element);
                const userData = {
                    ussd: element.ussd, 
                    fullname: element.fullname, 
                    created_at: moment(element.created_at).format("DD-MM-YYYY hh:mm:ss"), 
                    last_activity_date:  element.last_activity_date !== undefined ? moment(element.last_activity_date).format("DD-MM-YYYY hh:mm:ss") : null,
                    referral: element.referal_code,
                    role: element.role,
                    stores: element.stores,
                    // element.store_id,
                    action: this.action(element)
            };
                userDatas.push(userData);
            });

            this.setState({data: userDatas, isLoading: false});
            
            
          }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
    };

    deleteNotification = (notification) => {
        console.log("NOTIFICATION", notification);
        // return;
        axios
        .delete(
                Api.deleteNotification(notification._id),
                Api.getRequestHeader()
        )
        .then((response) => {
            console.log(response.data);

            
            if(response.data.success === true){
                this.setState({snackbarOpen: true, snackbarMessage: response.data.data}, () => {
                    this.loadPendingNotification();
                });
                
            }else{
                this.setState({snackbarOpen: true, snackbarMessage: response.data.error}, () => {});
            }

        }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({snackbarOpen: true, snackbarMessage: error.response.data.error}, () => {});
        });
    }

    loadPendingNotification = () => {

        const params = {
            filter :{},
            sort : {"_id":1},
            limit : 50
        }

        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        // this.setState({}, () => {
            axios
          .post(
            Api.listScheduledNotification(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("PENDINGNOTIFICATIONS", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            responseData.messages.forEach(element => {
                userDatas.push(element);
                // console.log(element);
            //     const userData = {
            //         ussd: element.ussd, 
            //         fullname: element.fullname, 
            //         created_at: moment(element.created_at).format("DD-MM-YYYY hh:mm:ss"), 
            //         last_activity_date:  element.last_activity_date !== undefined ? moment(element.last_activity_date).format("DD-MM-YYYY hh:mm:ss") : null,
            //         referral: element.referal_code,
            //         role: element.role,
            //         // element.store_id,
            //         action: this.action(element)
            // };
            //     userDatas.push(userData);
            });

            this.setState({listPendingNotifications: userDatas});
            
            
          }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        // });
        
    };

    componentDidMount() {
        this.handleReload();
    }

    handleReload = () => {
        this.loadUsers();
        this.loadPendingNotification();
        this.loadUserSupportData(); 
        this.gaReportActiveusers();
    }

    toggleDrawer = (open, userInfo, mode) => (event) => {
        console.log(open, event);
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }

        this.setState({right: open, selectedUser: userInfo, dialogMode: mode }, ()=>{
            console.log(this.state.dialogMode)
            if(this.state.dialogMode === this.DIALOG_MODE_PENDING_NOTIFIKASI){
                this.loadPendingNotification();
            }
        });
    };

    action = (userInfo) => {      
        return (<span className="btn btn-primary" onClick={this.toggleDrawer(true, userInfo, this.DIALOG_MODE_USER_INFO)}>Info</span>);
    }

    list(){
        return JSON.stringify(this.state.selectedUser) === "{}" ? (<div></div>) :  (
        <div className="info" role="presentation"
        //   onClick={this.toggleDrawer(anchor, false)}
        //   onKeyDown={this.toggleDrawer(anchor, false)}
        >
          <div className="container">
              <div className="row">
                  <div className="col-11">
                      <h1>Detail Informasi</h1>
                  </div>
                  <div className="col-1">
                      <CloseRounded onClick={this.toggleDrawer(false, {}, this.DIALOG_MODE_USER_INFO)} />
                  </div>
              </div>
              <br /><br />
                <div className="text-center">
                    <img src="https://picsum.photos/id/1/200/300" className="avatar" alt=""/>
                </div>
              <br />
                <div className="row row-padding">
                    <div className="col-4">Nama</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.fullname}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Id User</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.ussd}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Phone Number</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.ussd}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Tipe Akun</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.role}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4"></div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.stores[0].nama_toko}</strong> - {this.state.selectedUser.stores[0].jenis_usaha}
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Login Terakhir</div>
                    <div className="col-8 text-right">
                        <strong>{moment(this.state.selectedUser.created_at).format("LLL")}</strong>
                    </div>
                </div>

                <div className="text-center">
                    <br />
                <span className="btn btn-primary btn-rounded">
                    Non Aktifkan
                </span>
                </div>
          </div>
        </div>
      );
    }

    getFilterClasses = (role) => {
        let classes = "col-4";
        if(role === this.state.role){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    handleChangeFilter = (role) => {
        console.log("ROLE : ", role);
        this.setState({role: role});
        this.loadUsers(role);
    }

    handleKeywordChange = (event) => {
        this.setState({keyword: event.target.value});
    }

    handleFieldChange = (event) => {
        this.setState({field: event.target.value});
    }

    colors = () => {
        return [
          'rgba(28, 254, 156, 0.7)',
          'rgba(244, 182, 232, 0.7)',
          'rgba(88, 224, 68, 0.7)',
          'rgba(13, 133, 22, 0.7)',
          'rgba(66, 21, 49, 0.7)',
          'rgba(15, 61, 144, 0.7)',
          'rgba(63, 71, 53, 0.7)',
          'rgba(110, 47, 103, 0.7)',
          'rgba(251, 143, 5, 0.7)',
          'rgba(230, 65, 106, 0.7)',
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(255, 159, 64, 0.7)',
        ];
      };

    handleDateChanged = (rangeDate) => {

        let startDate = null;
        let endDate =  null;

        if(rangeDate[0] !== null && rangeDate[1] !== null){
            startDate = moment(rangeDate[0]).format("YYYY-MM-DD");
            endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
        }
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.handleReload()});
    }

    loadUserSupportData = () => {

        const params = {
          filter: {
              dateStart: this.state.startDate,
              dateEnd: this.state.endDate
          },
          sort: {
              _id: -1
          },
          limit: 20
        };
    
        axios
          .post(
            Api.userSupport(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
    
            const data = response.data;
    
            //Kategori Langganan
            const kategoriUser = data.chartKategoriUser            ;
            const cloneChartKategoriUser = {...this.state.chartKategoriUser};
            let datasetsData = [];
            cloneChartKategoriUser.datasets = [];
            cloneChartKategoriUser.labels = [];
            kategoriUser.forEach(item => {
              cloneChartKategoriUser.labels.push(item._id.role);
              datasetsData.push(item.count);
            });
    
            cloneChartKategoriUser.datasets = [
              {
                label: "",
                data: datasetsData,
                backgroundColor: this.colors(),
                // backgroundColor: "#165BAA",
              }
            ];
            // End kategori langganan
    
            //Jumlah Toko
            const chartUser = data.chartUser;
            const cloneChartUser = {...this.state.chartUser};
            let datasetsDataUser = [];
            cloneChartUser.datasets = [];
            cloneChartUser.labels = [];
            let jumlahUser = 0;

            let usersByRole = [];//{roleName: "", byYearMonth: []}
            chartUser.forEach(item => {
                let role = item._id.role;
                let findRole = usersByRole.find(byRole => byRole.roleName === role);

                const dataByYearMonth = {
                    year: item._id.year,
                    month: item._id.month,
                    role: item._id.role,
                    count: item.count,
                };

                if(findRole){
                    findRole.byYearMonth.push(dataByYearMonth);
                    findRole.counts.push(dataByYearMonth.count);
                }else{
                    usersByRole.push({
                        roleName: role,
                        byYearMonth: [dataByYearMonth],
                        counts: [dataByYearMonth.count]
                    });
                }

            });

            chartUser.forEach(item => {
              let splitYear = `${item._id.year}`.split("");
              cloneChartUser.labels.push(`${splitYear[2]}${splitYear[3]}-${item._id.month}`);
            //   datasetsDataUser.push(item.count);
              jumlahUser += item.count;
            });

            cloneChartUser.labels.sort();
            
            usersByRole.forEach((item, index) => {

                let defaultData = [];
                cloneChartUser.labels.forEach(clone => {
                    defaultData.push(0);
                });

                item.byYearMonth.forEach(by => {
                    let splitYear = `${by.year}`.split("");
                    let keyLabel = `${splitYear[2]}${splitYear[3]}-${by.month}`;

                    let findIndex = cloneChartUser.labels.findIndex(label => label === keyLabel);

                    if(findIndex >= 0){
                        defaultData[findIndex] = by.count;
                    }

                });

                cloneChartUser.datasets.push(
                    {
                      label: item.roleName,
                      data: defaultData,
                      backgroundColor: this.colors()[index], 
                      // backgroundColor: "#165BAA",
                    }
                );
            });

            this.setState({
              chartKategoriUser: cloneChartKategoriUser, 
              chartUser: cloneChartUser, 
              jumlahUser: jumlahUser,
            //   storeTransactions: storeTrans
            }, () => {
              console.log("LOG___USERS", this.state.chartKategoriUser, "chartUser", this.state.chartUser);
            });
    
          }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
    }

    gaReportActiveusers = () => {
        const params = {
          dateRanges: [
            {
              startDate: this.state.startDate,
              endDate: this.state.endDate,
            //   startDate: this.state.startDate.format("YYYY-MM-DD"),
            //   endDate: this.state.endDate.format("YYYY-MM-DD"),
            },
          ],
          dimensions: [
            {
              name: 'city',
            },
            {
              name: 'country',
            },
          ],
          metrics: [
            {
              name: 'activeUsers',
            },
          ],
        };
    
        console.log("GAREPORT_PARAMS", params);
  
        this.setState({isLoading: true}, () => {
          axios
          .post(
            Api.gaReport(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("GAREPORT", response.data);
  
            var totalActiveUsers = 0;
            var dataActiveUsers = [];
            var labels = [];
            var data = [];
            var i = 1;
            var plainData = [];
            response.data.rows.forEach(element => {
              
              // dataActiveUsers.push(
              //   {
              //       label: element.dimensionValues[0].value,
              //       data: element.metricValues[0].value,
              //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
              //   }
              // );
              if(i <= 10){
                data.push(element.metricValues[0].value);
                labels.push(element.dimensionValues[0].value);
                plainData.push({dimension: element.dimensionValues[0].value, metric: element.metricValues[0].value});
                ++i;
              }
              
              totalActiveUsers += parseInt(element.metricValues[0].value);
            });
  
            dataActiveUsers.push(
              {
                  label: "",
                  data: data,
                  // backgroundColor: this.colors(),
                  backgroundColor: "#165BAA",
              }
          );
  
            this.setState({isLoading: false, activeUsersPlainData: plainData, totalActiveUsers: totalActiveUsers, gaReportData: response.data.rows, activeUsersData: {labels: labels, datasets: dataActiveUsers}});
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      };

    render() { 
        return ( 
            <div>
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{}, this.DIALOG_MODE_USER_INFO)}
                    >
                    {
                        this.state.dialogMode === this.DIALOG_MODE_USER_INFO ? 
                        (
                            <UserInfo
                                onCloseDrawer={() => this.toggleDrawer(false,{}, this.DIALOG_MODE_USER_INFO)}
                                selectedUser={this.state.selectedUser}
                            />) : (<></>)
                    }

                    {
                        this.state.dialogMode === this.DIALOG_MODE_KIRIM_NOTIFIKASI ? 
                        (
                            <SendNotification
                                onCloseDrawer={() => this.toggleDrawer(false,{}, this.DIALOG_MODE_USER_INFO)}
                                selectedUser={this.state.selectedUser}
                            />) : (<></>)
                    
                    }
                    {
                        this.state.dialogMode === this.DIALOG_MODE_PENDING_NOTIFIKASI ? 
                        (
                            <PendingNotification
                                onCloseDrawer={() => this.toggleDrawer(false,{}, this.DIALOG_MODE_USER_INFO)}
                                selectedUser={this.state.selectedUser}
                                pendingNotificationsData={this.state.listPendingNotifications}
                                onDeleteNotification={this.deleteNotification}
                            />) : (<></>)
                    }
                  </Drawer>
                </React.Fragment>
              ))}

                <div className="container-fluid no-padding">
                    <div className="container-fluid header-page">
                        {/* Start of filter */}
                        <div className="row" style={{marginBottom: "10px"}}>
                            <div className="col-8">
                                <TextField 
                                    onChange={this.handleKeywordChange}
                                    id="txt-keyword" 
                                    value={this.state.keyword} 
                                    fullWidth 
                                    label="Keyword" variant="outlined" 
                                    size='small'
                                />
                            </div>
                            <div className="col-4" style={{backgroundColor: ""}}>
                            <div className="row">
                                {/* <div className="col-3"> */}
                                    {/* <FormControl size='small' fullWidth>
                                        <InputLabel id="cb-lokasi">Lokasi</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={"Semua"}
                                            label="Lokasi"
                                            onChange={(event) => {}}
                                            >
                                            <MenuItem value={"Semua"}>Semua</MenuItem>
                                            <MenuItem value={20}>?</MenuItem>
                                            <MenuItem value={30}>??</MenuItem>
                                        </Select>
                                    </FormControl> */}
                                {/* </div> */}
                                <div className="col-12 text-center">
                                    <small>Durasi</small>&nbsp;
                                    <DateRangePicker onDateChanged={this.handleDateChanged} />
                                    {/* <MyDateRangePicker startDate={this.state.startDate} endDate={this.state.endDate} onDateChanged={this.handleDateChanged}/> */}
                                </div>
                                {/* <div className="col-3"> */}
                                    {/* <Button fullWidth variant='outlined' color='primary' size='small'>
                                        Export <PictureAsPdf />
                                    </Button> */}
                                {/* </div> */}
                            </div>
                            </div>
                        </div>
                        {/* End of filter */}
                        
                        <div className="row">
                            <div className="col-4">
                                <Paper elevation={2} sx={{padding: "10px"}}>
                                    <div className="row" style={{padding: "10px"}}>
                                        <div className="col-10" style={{color: "#A1A1A1"}}>
                                            <Box style={{fontWeight: "bold"}}>
                                                Kategori User
                                            </Box>
                                            <Box>
                                                <small>User berdasarkan role</small>
                                            </Box>
                                        </div>
                                        <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                                    </div>
                                    <Divider />
                                    <PieChart data={this.state.chartKategoriUser} />
                                </Paper>
                            </div>
                            <div className="col-4">
                                <Paper elevation={2} sx={{padding: "10px"}}>
                                    <div className="row" style={{padding: "10px"}}>
                                        <div className="col-10" style={{color: "#A1A1A1"}}>
                                            <Box style={{fontWeight: "bold"}}>
                                                Jumlah User
                                            </Box>
                                            <Box>
                                                <small>{this.state.jumlahUser}</small><br />
                                                <small>User berdasarkan role</small>
                                            </Box>
                                        </div>
                                        <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                                    </div>
                                    <Divider />
                                    <LineChart data={this.state.chartUser} />
                                </Paper>
                            </div>
                            <div className="col-4">
                            <Paper elevation={2} sx={{padding: "10px", mt: "0px", mb: "20px"}}>
                                <div className="row" style={{padding: "10px"}}>
                                <div className="col-10" style={{color: "#A1A1A1"}}>
                                    <Box style={{fontWeight: "bold"}}>
                                        Lokasi Toko
                                    </Box>
                                    <Box>
                                        <small>Top 5 toko berdasarkan lokasi</small>
                                    </Box>
                                </div>
                                <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                                </div>
                                <BarChart data={this.state.activeUsersData} />
                            </Paper>
                            </div>
                        </div>
                        
                        {/* <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                                <h2 className="mt-4">User Management</h2>
                            </div>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                <p className="mt-4">
                                Sebagai halaman monitoring dan manajemen pengelolaan<br />user dari aplikasi<br />
                                </p>
                            </div>
                        </div> */}
                    </div>
                    <br />
                    <div className="container-fluid dashboard-content">
                        {/* <div className="row">
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                <div className="row">
                                    <div className="col-6">
                                        <TextField 
                                            onChange={this.handleKeywordChange}
                                            id="txt-keyword" 
                                            value={this.state.keyword} 
                                            fullWidth 
                                            label="Keyword" variant="outlined" 
                                            size='small'
                                        />
                                    </div>
                                    <div className="col-4">
                                        <DateRangePicker onDateChanged={this.handleDateChanged} />
                                    </div>
                                    <div className="col-2">
                                        <div className="btn btn-primary btn-block" style={{marginTop: 0}} onClick={() => this.loadUsers()}>Filter</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-8 col-xs-12">
                                <div className="row filter-container">
                                    <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                        Semua
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("owner store")} className={this.getFilterClasses("owner store")}>
                                        Pemilik
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("cashier")} className={this.getFilterClasses("cashier")}>
                                        Kasir
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="col-6">
                                <div className='row' style={{border: "solid 1px #dededd", padding: "0px",  maxWidth: "320px", borderRadius: "10px"}}>
                                    <div className="col-1" style={{maxWidth: "20px", padding: "5px"}}>
                                        <Send style={{color: "#2196f3"}} />
                                    </div>
                                    <div className="col-9" style={{padding: "5px", marginLeft: "10px", cursor: "pointer"}} onClick={this.toggleDrawer(true,{}, this.DIALOG_MODE_PENDING_NOTIFIKASI)}>
                                        <div className="row">
                                            <div className="col-10" style={{color: "#2196f3"}}>
                                                Kirim  Pesan
                                            </div>
                                            <div className="col-1">
                                                <div className="badge badge-danger">{this.state.listPendingNotifications.length}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={this.toggleDrawer(true,{}, this.DIALOG_MODE_KIRIM_NOTIFIKASI)} className="col-1 text-center" style={{cursor: "pointer",backgroundColor: "#2196f3",maxWidth: "32px", color: "#FFFFFF", padding: "5px", marginLeft: "17px", borderRadius: "0 10px 10px 0"}}>
                                        <Add />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 text-right">
                                <ExportDataUser userData={this.state.data}/>
                            </div>
                        </div>
                        <DataTable 
                            title={"Daftar Pengguna PosSaku"} 
                            data={this.state.data} 
                            columns={this.state.columns} 
                            options={this.options} 
                            // selectableRows
                            pagination
                            progressPending={this.state.isLoading}
                            // progressComponent={<div>Loading</div>}
                        />

                    </div>
                </div>
                <Snackbar
                    open={this.state.snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => {this.setState({snackbarOpen: false})}}
                    message={this.state.snackbarMessage}
                    // action={action}
                    />
            </div>
        );
    }
}
 
export default DashboardUserManagement;