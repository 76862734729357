import React, { Component, useState } from 'react';
import "./AdminAccess.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import moment from 'moment';
import DataTable from 'react-data-table-component';
// import ExportStores from './ExportStores';
// import StoreTransactionList from './StoreTransactionList';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem, Radio, RadioGroup, Snackbar, TextField, Tooltip } from '@mui/material';
// import DownloadTemplateProductBulk from './DownloadTemplateProduct';
import { Add, Assignment, AttachMoney, Close, DoneAll, Edit, Error, Image, ImageOutlined, Info, Logout, MoreVert, PriceCheck, Search, Settings, Subscript, Tag } from '@mui/icons-material';
import { FileUploader } from 'react-drag-drop-files';
// import DialogStoreInfoContent from './Dialogs/DialogStoreInfo';
// import DialogAturSubscription from './Dialogs/DialogAturSubscription';
import DialogFormAdmin from './Dialogs/DialogFormAdmin';

class AdminAccess extends Component {
    state = { 
        columns : [
            {
                name: "User Name",
                selector: row => row.username,
                sortable: true
            },
            {
                name: "Full Name",
                selector: row => row.fullname,
                sortable: true
            },
            {
                name: "Keterangan",
                selector: row => "",
                sortable: true
            },
            {
                name: "Privileges",
                selector: row => row.access.toString(),
                sortable: true,
                width: "40%"
            },
            // {
            //     name: "Join Date",
            //     selector: row => row.join_date,
            //     sortable: true,
            //     sortFunction: (rowA, rowB) => {
            //         const a = moment(rowA.join_date).toDate();
            //         const b = moment(rowB.join_date).toDate();

            //         return a > b ? 1 : -1

            //     }
            // },
            {
                name: "",
                selector: row => row.action
            }
        ],
        data : [], 
        //all, has transaction, no transaction
        filter: "all",
        currentStore: {},
        dialogOpen: false,
        dialogAturSUbscriptionOpen: false,
        dialogTambahUsahaOpen: false,
        selectedStore: null,
        snackbarOpen: false,
        snackbarMessage: "",
        anchorElMenu: null,
        showMenu: false,
        menustoreInfo: null,
        query: "",
        opsiFieldPencarian: "fullname"
    }

    options = {
        filterType: 'checkbox',
    };

    loadAdmins = () => {
    // loadUsers = () => {

        let paramFilter = {};

        // if(this.state.filter === "all"){
        //     paramFilter = {};
        // }

        // if(this.state.filter === "has transaction"){

        // }

        // if(this.state.filter === "no transaction"){
        //     paramFilter = {last_transaction: null};
        // }

        // if(this.state.query && this.state.query.length > 0){
        //     let selectedField = this.state.opsiFieldPencarian;

        //     if(selectedField === "fullname"){
        //         paramFilter.nama_toko = {$regex: this.state.query, $options: "i"};
        //     }else if(selectedField === "ussd"){
        //         paramFilter.owner_ussd = {$regex: this.state.query, $options: "i"};
        //     }
            
        // }

        let params = {
            filter: {role: "admin"},
            sort:{ussd: -1},
            limit:100
        };

    
        console.log("PARAMS STORES", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .post(
            Api.adminUserList(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("STORERESPONSE", response.data);
            const responseData = response.data;
            // const userDatas = [...this.state.data];
            const userDatas = [];
            
            responseData.data.forEach(element => {
                // console.log(element);
                // const userData = {
                //     alamat: element.alamat, 
                //     nama_toko: element.nama_toko, 
                //     transaksi: element.last_transaction !== undefined ? moment(element.last_transaction).format("DD-MM-YYYY HH:mm:ss") : null, 
                //     action: this.action(element)
                // };
                // element.transaksi = element.last_transaction !== undefined ? moment(element.last_transaction).format("DD-MM-YYYY HH:mm:ss") : null;
                // element.join_date = element.created_at !== undefined ? moment(element.created_at).format("DD-MM-YYYY") : null;
                // element.akhir_subscription = element.valid_thru !== undefined ? moment(element.valid_thru).format("DD-MM-YYYY HH:mm:ss") : null;
                element.action = this.action(element)
                userDatas.push(element);
            });

            this.setState({data: userDatas});
            
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    componentDidMount() {
        this.loadAdmins(null);
    }

    handleChangeFilter = (filter) => {
        this.setState({filter: filter}, () => {
            this.loadAdmins();
        });
    }

    handleShowMenu = (event, storeInfo) => {
        this.setState({anchorElMenu: event.currentTarget, showMenu: true, menustoreInfo: storeInfo});
    }

    handleCloseMenu = (event, storeInfo) => {
        this.setState({anchorElMenu: null, showMenu: false, menustoreInfo: null});
    }

    getFilterClasses = (filter) => {
        let classes = "col-4";
        if(filter === this.state.filter){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    action = (storeInfo) => {
        return (
            <IconButton onClick={(event) => this.handleShowMenu(event, storeInfo)} color='primary'>
                <MoreVert />
            </IconButton>
        );
        // return (<div>
            {/* <Tooltip key={"tooltip1"} title="Lihat Transaksi"> */}
                {/* <span title='Lihat transaksi' style={{cursor: "pointer"}} onClick={this.toggleDrawer(true, storeInfo)} className="text-primary"><Assignment /> </span> */}
            {/* </Tooltip> */}
            {/* &nbsp; */}
            {/* <Tooltip key={"tooltip2"} title="Download template upload product bulk"> */}
                {/* <DownloadTemplateProductBulk storeId={storeInfo.id} /> */}
            {/* </Tooltip> */}
            {/* &nbsp; */}
            {/* <span  title="Detail Toko" style={{cursor: "pointer"}} className="btna btna-primary btna-sm text-primary" onClick={() => this.showDialogInfo(storeInfo)}><Info /></span>
            <span  title="Atur Subscription" style={{cursor: "pointer"}} className="btna btna-primary btna-sm text-primary" onClick={() => this.showDialogAturSubscription(storeInfo)}><AttachMoney /></span> */}
            {/* <span  title="Tambah Usaha" style={{cursor: "pointer"}} className="btna btna-primary btna-sm text-primary" onClick={() => this.showDialogTambahUsaha(storeInfo)}><AttachMoney /></span> */}
            
            
        // </div>);
    }

    toggleDrawer = (open, storeInfo) => (event) => {
        console.log(event);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({right: open, currentStore: storeInfo}, ()=>{
            
        });
    };

    showDialogInfo = (storeInfo) => {
        this.setState({selectedStore: storeInfo, dialogOpen: true});
    }

    showDialogAturSubscription = (storeInfo) => {
        this.setState({selectedStore: storeInfo, dialogAturSUbscriptionOpen: true});
    }

    showDialogTambahUsaha = (storeInfo) => {
        this.setState({selectedStore: storeInfo, dialogTambahUsahaOpen: true});
    }

    handleShowSnackbar = (message) => {
        this.setState({snackbarOpen: true, snackbarMessage: message});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarOpen: false, snackbarMessage: ""});
    }

    handleQueryChange = (event) => {

        let value = event.target.value && event.target.value.length > 0 ? event.target.value : "";

        this.setState({query: value})
    }

    onKeyDownHandler = (e) => {

        if(e.keyCode === 13){
            this.loadAdmins();
        }

        // if(e.keyCode === 13 && e.ctrlKey){
        //     this.props.onSendMessage();
        //     console.log("CTRL + ENTER");
        // }else if(e.keyCode === 13 && e.shiftKey){
        //     this.props.onSendMessage();
        //     console.log("CTRL + SHIFT");
        // }else if (e.keyCode === 13) {
        //     console.log("ENTER");
        // }
    };

    handleOpsiFieldPencarianChange = (event) => {
        console.log("OPSI", event);
        this.setState({opsiFieldPencarian: event.target.value}, () => {
            this.loadAdmins();
        })
    }

    render() { 
        return ( 
            <div className="container-fluid no-padding">

            {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                    <Drawer anchor={anchor} open={this.state[anchor]} 
                        onClose={this.toggleDrawer(false,0)}
                        >
                        {/* <StoreTransactionList 
                            storeInfo={this.state.currentStore}
                            // onClose={this.toggleDrawer(false, 0)}
                        /> */}
                    </Drawer>
                </React.Fragment>
            ))}

            <Snackbar
                open={this.state.snackbarOpen}
                autoHideDuration={4000}
                onClose={this.handleCloseSnackbar}
                message={this.state.snackbarMessage}
            />

            <Menu
                anchorEl={this.state.anchorElMenu}
                id="account-menu"
                open={this.state.showMenu}
                onClose={this.handleCloseMenu}
                onClick={this.handleCloseMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={(event) => this.showDialogTambahUsaha(this.state.menustoreInfo)}>
                    <ListItemIcon>
                        <Edit color='secondary' fontSize='small' />
                    </ListItemIcon>
                    Edit
                </MenuItem>
                {/* <MenuItem onClick={() => this.showDialogInfo(this.state.menustoreInfo)}>
                    <ListItemIcon>
                        <Info color='secondary' fontSize='small' />
                    </ListItemIcon>
                    Detail Toko
                </MenuItem>
                <MenuItem onClick={() => this.showDialogAturSubscription(this.state.menustoreInfo)}>
                    <ListItemIcon>
                        <AttachMoney color='secondary' fontSize="small" />
                    </ListItemIcon>
                    Atur Subscription
                </MenuItem> */}
            </Menu>

            {/* {
                (this.state.selectedStore !== null && this.state.dialogOpen === true) && (
                    <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={this.state.dialogOpen}
                        onClose={() => {this.setState({dialogOpen: false})}}
                    >
                        <DialogTitle>
                            <div className="row">
                                <div className="col-11">
                                    <h3 className='text-primary'>Detail Toko</h3>
                                </div>
                                <div className="col-1 text-right">
                                    <IconButton color='error' onClick={() => {this.setState({selectedStore: null, dialogOpen: false})}}><Close /></IconButton>
                                </div>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>
                            {
                                this.state.selectedStore !== null && <DialogStoreInfoContent storeInfo={this.state.selectedStore} showSnackbar={this.handleShowSnackbar}/>
                            }
                        </DialogContent>
                        <DialogActions></DialogActions>
                    </Dialog>
                ) 
            } */}

            {/* {
                (this.state.selectedStore !== null && this.state.dialogAturSUbscriptionOpen === true) && (
                    <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={this.state.dialogAturSUbscriptionOpen}
                        onClose={() => {this.setState({dialogAturSUbscriptionOpen: false})}}
                    >
                        <DialogTitle>
                            <div className="row">
                                <div className="col-11">
                                    <h3 className='text-primary'>Atur Subscription</h3>
                                </div>
                                <div className="col-1 text-right">
                                    <IconButton color='error' onClick={() => {this.setState({selectedStore: null, dialogAturSUbscriptionOpen: false})}}><Close /></IconButton>
                                </div>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>
                            {
                                this.state.selectedStore !== null && <DialogAturSubscription storeInfo={this.state.selectedStore} showSnackbar={this.handleShowSnackbar}/>
                            }
                        </DialogContent>
                        <DialogActions></DialogActions>
                    </Dialog>
                ) 
            } */}
            {
                (this.state.selectedStore !== null && this.state.dialogTambahUsahaOpen === true) && (
                    <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={this.state.dialogTambahUsahaOpen}
                        onClose={() => {this.setState({dialogTambahUsahaOpen: false})}}
                    >
                        <DialogTitle>
                            <div className="row">
                                <div className="col-11">
                                    <h3 className='text-primary'>{this.state.selectedStore ? "Edit Admin" : "Tambah Admin"} </h3>
                                </div>
                                <div className="col-1 text-right">
                                    <IconButton color='error' onClick={() => {this.setState({selectedStore: null, dialogTambahUsahaOpen: false})}}><Close /></IconButton>
                                </div>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>
                            {
                                this.state.selectedStore !== null && <DialogFormAdmin adminData={this.state.selectedStore} reload={this.loadAdmins} onClose={() => {this.setState({dialogTambahUsahaOpen: false})}} showSnackbar={this.handleShowSnackbar} />
                            }
                        </DialogContent>
                        <DialogActions></DialogActions>
                    </Dialog>
                ) 
            }

                <div className="container-fluid header-page">
                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                            <h3>User Terdaftar</h3>
                        </div>
                        <div className="col-md-9 col-lg-9  col-sm-12 col-xs-12 text-right">
                            <Button size='small' variant='contained' color='primary' onClick={(event) => this.showDialogTambahUsaha()}><Add /> Tambah Admin</Button>
                        </div>
                    </div>
                </div>

                <div className="container-fluid dashboard-content">
                    {/* <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            &nbsp;
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div className="row filter-container">
                                <div className={this.getFilterClasses("all")} onClick={() => this.handleChangeFilter("all")}>
                                    Semua
                                </div>
                                <div className={this.getFilterClasses("has transaction")} onClick={() => this.handleChangeFilter("has transaction")}>
                                    Ada Transaksi
                                </div>
                                <div className={this.getFilterClasses("no transaction")} onClick={() => this.handleChangeFilter("no transaction")}>
                                    Tanpa Transaksi
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <br /> */}
                    {/* <div className="row">
                        <div className="col-122">
                            <Box sx={{display: "flex", flexDirection: "row"}}>
                                <div style={{paddingTop: "6px", fontWeight: "bold", marginRight: "15px", marginLeft: "15px"}}>Opsi Pencarian</div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="fullname"
                                        name="radio-buttons-group"
                                        row
                                        onChange={this.handleOpsiFieldPencarianChange}
                                    >
                                        <FormControlLabel value="fullname" control={<Radio size='small' />} label="Nama Toko" />
                                        <FormControlLabel value="ussd" control={<Radio size='small' />} label="No HP" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    </div> */}
                    {/* <div className="row">
                        <div className="col-11">
                            
                            <TextField
                                id="txtQuery"
                                label={"Pencarian " + (this.state.opsiFieldPencarian === "fullname" ? "nama toko" : "no hp")}
                                defaultValue={""}
                                type='text'
                                fullWidth
                                size='small'
                                onChange={this.handleQueryChange}
                                onKeyDown={this.onKeyDownHandler}
                            />
                        </div>
                        <div className="col-1" style={{paddingTop: "4px"}}>
                            <Button onClick={this.loadStores} color='primary' size='small' fullWidth variant='contained'><Search /> Cari</Button>
                        </div>
                    </div> */}
                    {/* <br /> */}

                    <DataTable 
                        title={""} 
                        data={this.state.data} 
                        columns={this.state.columns} 
                        options={this.options} 
                        pagination
                        // expandableRows
                    />
                </div>
                
            </div>

         );
    }
}
 
export default AdminAccess;

